.container {
  font-family: 'Roboto', sans-serif;
  color: #333; /* Dark grey text */
  
}
.my-container{
  /* margin-bottom: 150px; */
  margin-top: 100px;
}
.my-Product{
  margin-top: 100px;
}

.image{
  width: 80%;
  margin-top: 40px;
}

.my-container{
  margin-top: 150px;
  margin-bottom: 150px;
}

h1, h2, h3, h4, h5, h6 {
  color: #464545; /* This color might not be visible due to the gradient text effect */
  font-weight: bold;
  background: linear-gradient(to right, blue, red);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}


h3 {
  margin-top: 20px; /* Vertical space above h3 headings */
  margin-bottom: 10px; /* Vertical space below h3 headings */
}

p {
  font-size: 16px; /* Suitable font size for readability */
  line-height: 1.6; /* Spacing between lines */
  margin-bottom: 20px; /* Vertical space below paragraphs */
}
.card-img {
  width: 100%;     /* makes the image responsive */
  height: auto;    /* maintain aspect ratio */
  display: block;  /* remove extra space below the image */
}

.text-content:hover{
  background-color: red; /* Change to your desired light color */
  transform: scale(1.05); /* Zoom in effect on hover */
    border-radius: 15px;
    box-shadow: #464545;
}

.product-image:hover{
  background-color: red; /* Change to your desired light color */
  transform: scale(1.05); /* Zoom in effect on hover */
  border-radius: 15px;
  box-shadow: #464545;
}


/* ItzoneProduct.css */
.card-body .carousel .slide {
  background: transparent; /* Remove default background */
}

.card-body .carousel .carousel-status {
  display: none; /* Hide the default counter if not needed */
}

.card-img-top {
  width: 100%;
  height: auto;
  object-fit: cover; /* Adjust this if you want different image fit options */
}

/* Adjust the height of the text card to match the carousel */
.card.h-100 {
  display: flex;
  flex-direction: column;
}

.card-body {
  flex: 1; /* Allows card-body to expand and consume available space */
}


/* first bg image */
.background-container {
  position: relative;
  /* text-align: center; */
  width: 100%;
}

.product-image {
  width: 70%;
  height: auto;  /* Ensure image maintains aspect ratio */
  transition: transform 0.3s ease, background-color 0.3s ease;}

.text-content {
  position: absolute;
  top: 25%;  
  left: 40%;
  right: 0%;
  background: rgba(255, 255, 255, 0.9);
  transition: transform 0.3s ease, background-color 0.3s ease;

}

@media (max-width: 992px) { /* Adjustments for iPad and similar devices */
  .text-content  {

    position: absolute;
    top: 13%;  /* Start at 20% from the top */
    left: 40%;
    right: 0%;
    background: rgba(255, 255, 255, 0.9);

    position: static;
    padding: 20px;
    margin-top: -20%;  /* Pull text content up to overlap image slightly */

  }
  .product-image {
    margin-left: 10%;
    margin-right: 10%;
    width: 80%;  /* Reduce image width with margins */
  }
}
/* End bg image */
@media (max-width: 991px) { /* Adjustments for mobile devices */
  .text-content  {
    position: static;
    margin-top: 20px;
  }
  .product-image {
    width: 100%;  /* Full width for smaller devices */
    margin: 0;  /* Reset margins */
  }

  
  .red-dash {
    width: 50px; /* Adjust the width as needed */
    height: 10px; /* Adjust the thickness of the dash */
    background-color: red; /* The color of the dash */
    /* Centers the dash and adds space below */
  }
  
  .discover-more-btn {
    /* Add your styles for the button */
    background-color: red; /* Example button color */
    color: white; /* Text color */
    border: none; /* Remove default border */
    padding: 10px 20px; /* Padding inside the button */
    text-transform: uppercase; /* Uppercase button text */
    cursor: pointer; /* Change mouse cursor on hover */
    border-radius: 20px; /* Rounded corners for the button */
  }
  
  .discover-more-btn:hover {
    background-color: darkred; /* Darker background on hover */
  }
  
  .modal-content {
    background-color: #fff;
    border-radius: 10px; /* Rounded corners for modal */
  }
  
  .image{
    width: 100%;
  }

}
.content-container {
  /* Add your styles for the container */
  text-align: center; /* if you want to center everything */
}

.red-dash {
  width: 50px; /* Adjust the width as needed */
  height: 10px; /* Adjust the thickness of the dash */
  background-color: red; /* The color of the dash */
  /* Centers the dash and adds space below */
}

.discover-more-btn {
  /* Add your styles for the button */
  background-color: red; /* Example button color */
  color: white; /* Text color */
  border: none; /* Remove default border */
  padding: 10px 20px; /* Padding inside the button */
  text-transform: uppercase; /* Uppercase button text */
  cursor: pointer; /* Change mouse cursor on hover */
  border-radius: 20px; /* Rounded corners for the button */
}

.discover-more-btn:hover {
  background-color: darkred; /* Darker background on hover */
}

.modal-content {
  background-color: #fff;
  border-radius: 10px; /* Rounded corners for modal */
}

.image{
  width: 100%;
}



/* Ensure the column is a flex container and align items centrally */




.image1 {
    width: 100%;
    display: flex;
    align-items: center; /* This will center the image vertically */
    justify-content: center; /* This will center the image horizontally */
    padding-top: 10%;  /* Adjust width as necessary, keeping it responsive */
}

.image3{
   
    width: 100%;
}
/* Update or remove unnecessary properties for .image1 if they conflict */
.image1 {
  width: 100%;
  display: flex;
  align-items: center; /* This will center the image vertically */
  justify-content: center; /* This will center the image horizontally */
  padding-top: 10%;  /* Adjust width as necessary, keeping it responsive */
}

.image3{
 
  width: 100%;
  height: 60%;

}


/* second bg image */
.text-image {
    position: absolute;
    top: 20%;  /* Start at 20% from the top */
    left: 40%;
    right: 0%;
    background: rgba(255, 255, 255, 0.9);
  }
  
  @media (max-width: 992px) { 
    .text-image {
        position: absolute;
        top: 30%;  /* Start at 20% from the top */
        left: 40%;
        right: 0%;
        background: rgba(255, 255, 255, 0.9);
    
        position: static;
        padding: 10px;
        margin-top: -0%;  
    }
  }

  /*end od bg image  */



  
.ipad-class {
    display: none;
  }
  
  
  @media only screen 
    and (min-device-width: 768px) 
    and (max-device-width: 1024px) 
    and (-webkit-min-device-pixel-ratio: 1) {
      .ipad-class {
          display: block;
      }
  }
  
  /* Alternatively, if you want to show the image on all tablet sizes, not just iPads */
  @media only screen 
    and (min-width: 768px) 
    and (max-width: 1024px) {
      .ipad-class {
          display: block;
      }
  }




/* Initially hide elements and set them to move into view from the sides */
.content-animate, .image-animate {
  opacity: 0;
  will-change: transform, opacity;
}

.content-animate {
  transform: translateX(100%); /* Start content off to the right */
}

.image-animate {
  transform: translateX(-100%); /* Start images off to the left */
}

.animate-on-scroll {
  animation: slideIntoView 1s forwards;
}

@keyframes slideIntoView {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@media (max-width: 767px) {
  .image-animate, .content-animate {
      opacity: 1; /* Ensure visibility */
      transform: none; /* Reset transformations */
  }
}
 .section {
  scroll-margin-top: 100px;  /* Adjust this value based on your actual navbar height */
}