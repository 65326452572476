.about {
  margin: 100px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 90%;
}

.head {
  font-weight: bold;
  color: black;
}

.red-dash {
  width: 50px;
  height: 10px;
  background-color: red;
  margin-left: 10px;
}

.column_left {
  flex-basis: 40%;
  position: relative;
  margin-top: -150px;
}

.column_right {
  flex-basis: 56%;
  animation-name: jumpAndFall;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
}

@keyframes jumpAndFall {
  0% {
    transform: translateY(-100px);
  }
  50% {
    transform: translateY(50px);
  }
  100% {
    transform: translateY(0);
  }
}

.about-img {
  width: 70vw;
  border-radius: 10px;
  animation-name: jumpAndFall;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
}

/* Define keyframes for the fade-in animation */
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fade-in {
  animation-name: fade-in;
  animation-duration: 1s;
  animation-timing-function: ease-in;
  animation-fill-mode: both;
}

.column_right h3 {
  font-size: 20px;
  font-weight: 600;
  color: #212ea0;
  padding-left: 10px;
}

.column_right h2 {
  font-size: 35px;
  margin: 10px 0;
  color: #000f38;
  text-align: start;
  padding-left: 10px;
}

.column_right p {
  margin-bottom: 15px;
  color: #676767;
  text-align: justify;
}


/* media query */
@media (max-width: 800px) {
  .about {
    flex-direction: column;
  }
  .column_left {
    flex-basis: 100%;
    margin: 20px;
    order: 1;
    margin-top: -50px;
  }
  .column_right {
    flex-basis: 100%;
    order: 2;
  }
  .test {
    order: 3;
  }
  .cards {
    flex-basis: 100%;
    order: 4;
  }
}