.scroll-to-top {
    display: none; /* Keeps the button hidden until needed */
    position: fixed;
    bottom: 100px;
    right: 20px;
    background-color: #333; /* Dark background for contrast */
    color: white;
    border: none;
    border-radius: 50%; /* Makes the button round */
    width: 50px; /* Fixed width */
    height: 50px; /* Fixed height */
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px; /* Adjust icon size if necessary */
    cursor: pointer;
    opacity: 0.7;
    transition: opacity 0.3s ease, transform 0.3s ease;
}

.scroll-to-top.show {
    display: flex; /* Using flex to center the icon within the button */
}

.scroll-to-top:hover {
    opacity: 1;
    transform: translateY(-3px); /* Slight lift effect on hover */
}

.scroll-to-top:focus {
    outline: none; /* Removes the outline to keep the design clean */
}
