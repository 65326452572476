.overlay-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5); /* Adjust opacity here */
  color: white;
  padding: 20px; /* Adjust padding as needed */
  box-sizing: border-box;
}

.content-img{
  width: 100%;
  height: 600px;
  object-fit: cover;
  position: relative;
}

.content{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
}

.content-maintext{
  font-size: 50px;
  font-weight: bold;
}

.content-subtext{
  font-size: 25px;
}

@media (max-width: 767px) {
  .overlay-content {
    padding: 10px; 
  }
  
  .overlay-content div {
    text-align: center;
  }

  .content-img{
    width: 100%;
    height: 280px;
    object-fit: cover;
    position: relative;
  }

  .content-maintext{
    font-size: 16px; 
    
  }
  
  .content-subtext{
    display: none; /* Hide subtext on mobile screens */
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .overlay-content {
    padding: 15px; 
  }
  
  .overlay-content div {
    text-align: center;
  }

  .content-img{
    width: 100%;
    height: 400px;
    object-fit: cover;
    position: relative;
  }

  .content-maintext{
    font-size: 32px; 
  }
  
  .content-subtext{
    display: none; /* Hide subtext on mobile screens */
  }
}

