.video-background-container {
  position: relative;
  width: 100%;
  height: 620px;
  margin-bottom: 50px;
}
.video-background {
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* animation: fadeIn 1s ease-in-out; */
}
.videored-dash {
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translateX(-50%);
  width: 50px;
  height: 8px;
  background-color: red;
}
.main-text {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  text-align: center;
  font-size: 50px;
  /* font-family: 'FangSong', serif; */
  font-weight: bold;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  /* border-right: 3px solid orange; */
  box-sizing: border-box;
}

.line1, .line2 {
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  animation-duration: 5s;
  animation-timing-function: steps(60, end);
  animation-fill-mode: forwards;
  line-height: 1.2;  /* ensures minimal vertical space between lines */
  margin: 0; 
}

.line1 {
  width: 0;
  animation-name: typingLine1;
}

.line2 {
  width: 0;
  animation-name: typingLine2;
  animation-delay: 5s; /* Delay this animation until the first line is typed */
}
.sub-text {
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  text-align: center;
  font-size: 25px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  /* overflow: hidden;
  white-space: nowrap; */
  /* border-right: 3px solid orange; */
  box-sizing: border-box;
  /* animation: typingSubText 8s steps(120, end) forwards; */
}

.subline1, .subline2 {
  display:inline-block;
  overflow: hidden;
  white-space: nowrap;
  animation-duration: 5s;
  animation-timing-function: steps(60, end);
  animation-fill-mode: forwards;
  line-height: 1.2;  /* ensures minimal vertical space between lines */
  margin: 0; 
}

.subline1 {
  width: 0;
  animation-name: typingSubLine1;
  animation-delay: 6.6s; /* Delay this animation until after line2 is typed */
}

.subline2 {
  width: 0;
  animation-name: typingSubLine2;
  animation-delay: 11.6s; /* Delay this animation until after subline1 is typed */
}


.toggle-button {
  position: absolute;
  bottom: 20px;
  right: 10px;
  width: 40px;
  padding: 8px;
  border-radius: 30px;
  background-color: grey;
}
@media (max-width: 991px) {
  .video-background-container {
    height: 300px;
    margin-bottom: 30px;
  }
  .videored-dash {
    width: 30px;
    height: 5px;
  }
  .main-text, .sub-text {
    width: 70%;
  }
  .main-text {
    font-size: 25px;
    margin-bottom: 5px;
  }
  .sub-text {
    margin-top: 15px;
    font-size: 15px;
  }
  .toggle-button {
    bottom: 10px;
    right: 5px;
    width: 35px;
    padding: 6px;
  }
}
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}
@keyframes typingLine1 {
  from { width: 0; }
  to { width: 100%; }
}

@keyframes typingLine2 {
  from { width: 0; }
  to { width: 100%; }
}
/* @keyframes typingSubText {
  from { width: 0; }
  to { width: 100%; }
} */
@keyframes typingSubLine1 {
  from { width: 0; }
  to { width: 100%; }
}

@keyframes typingSubLine2 {
  from { width: 0; }
  to { width: 100%; }
}

.video-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black overlay */
}