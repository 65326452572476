.service-card {
  max-width: 23%;
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  opacity: 0;
  transform: translateY(100%); /* Start cards off-screen below */
  transition: opacity 1.5s ease, transform 1.5s ease; /* Transition properties */
}

.service-card.show {
  opacity: 1;
  transform: translateY(0); /* Move cards to their original position */
  transition: opacity 1.5s ease, transform 1.5s ease, background-color 0.5s ease; /* Include background-color transition if needed */
}

.service-img {
  display: block;
  margin: 0 auto;
  max-width: 100%;
  height: 150px;
  border-radius: 8px;
}

.service-card h3 {
  font-size: 18px;
  margin: 10px 0;
  color: #212ea0;
}

.service-card p {
  color: #676767;
  margin: 10px 0;
  align-items: start;
  text-align: justify;
}

.service-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

@media (max-width: 800px) {
  .service-card {
    max-width: 30%;
    margin-right: 0;
    opacity: 1; /* Ensure cards are visible */
    transform: translateY(0); /* Ensure cards are in their original position */
    transition: none; /* Remove transition effects */
  }

  .service-card:hover {
    background-color: transparent; /* Remove hover background color */
    transform: none; /* Remove hover scaling */
  }

  .service-card.show {
    transition: none; /* Remove transition effects */
  }
}

@media (max-width: 600px) {
  .service-card {
    max-width: 100%;
    margin-right: 0;
    opacity: 1; /* Ensure cards are visible */
    transform: translateY(0); /* Ensure cards are in their original position */
    transition: none; /* Remove transition effects */
  }

  .service-card:hover {
    background-color: transparent; /* Remove hover background color */
    transform: none; /* Remove hover scaling */
  }

  .service-card.show {
    transition: none; /* Remove transition effects */
  }
}
